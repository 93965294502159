.header-bg-images {
    min-width: 100%;
    height: 90vh;
    animation: animate 15s linear infinite;
    background-size: cover;
    background-repeat: no-repeat;
}

@keyframes animate {

    0%,
    100% {
        opacity: 1;
        background-image: url('../../public/images/vedic-bg-img-1.png');
    }

    33.33%,
    66.66% {
        opacity: 1;
        background-image: url('../../public/images/vedic-bg-img-2.png');
    }

    50% {
        opacity: 1;
        background-image: url('../../public/images/vedic-bg-img-3.png');
    }
}

.header-container-vedic-main-top {
    display: flex;
    justify-content: center;
    position: relative;
    background: none;
}

.header-background-image {
    width: 1340px;
}

.header-container-vedic {
    background-color: rgba(0, 0, 0, 0.4);
    width: 60%;
    padding: 30px;
    position: absolute;
    top: 150px;
}

.header-heading {
    display: flex;
}

.header-heading-elevate-txt {
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 900;
    width: 100%;
    text-align: center;
}

.header-para {
    margin: 15px;
    color: #FFFFFF;
    font-size: 15px;
}

.getProposal-btn {
    background-color: #CE3082;
    padding: 15px 30px 15px 30px;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    font-size: 15px;
}

.checkWork-btn {
    border: 1px solid #FAEAF3;
    background-color: #00000066;
    color: #FFFFFF;
    padding: 15px 30px 15px 30px;
    font-size: 15px;
    border-radius: 5px;
}

.arrow-btn {
    margin-left: 5px;
}

.header-btn-div {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.header-container {
    margin: 120px 3px 60px 33px;
}

.header-about-main {
    display: flex;
}

.header-about-left {
    text-align: start;
}

.header-about-left-heading {
    width: 90%;
    font-weight: 700;
    font-size: 50px;
}

.header-about-left-top-text {
    color: #CE3082;
    margin: 10px 0px 10px 0px;
    font-size: 26px;
    font-weight: 600;
}

.header-about-left-para {
    color: #767676;
    width: 100%;
    font-size: 15px;
}

.header-about-left-text {
    font-size: 18px;
    font-weight: 500;
}

.keyElements-heading {
    color: #232321;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
}

.keyElements-bottom-main-para {
    color: #595959;
    text-align: justify;
}

.black-image {
    height: 360px;
    width: 100%;
}

.header-keyElements-container {
    margin-top: 80px;
}

.keyElements-bottom-container {
    margin: 80px 5px 30px 10px;
}

.keyElements-bottom-main-heading-text {
    margin: 15px 0px 15px 0px;
    font-size: 18px;
    font-weight: 500;
}

.keyElements-bottom-main-images {
    height: 60px;
}

.header-ourprocess-div {
    line-height: 20px;
    margin: 20px 30px 40px 38px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    text-align: start;
}

.header-ourprocess-heading {
    font-family: Raleway;
    font-size: 30px;
    font-weight: 600;
    color: #CE3082;
}

.streamlined-heading {
    font-family: Noto Sans;
    font-size: 38px;
    font-weight: 600;
    color: #232321;
}

.discover-para {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 400;
    color: #767676;
}

@media (max-width:767px) {
    .header-about-main {
        display: block !important;
    }

    .header-container-main {
        width: 100%;
    }

    .header-container-vedic {
        width: 100%;
    }

    .header-bg-images {
        min-width: 50%;
        height: 40vh;
        animation: animate 10s ease-in-out infinite;
    }


    @keyframes animate {

        0%,
        100% {
            background-image: url('../../public/images/vedic-bg-img-1.png');
            background-size: cover;
            background-repeat: repeat;
        }

        25% {
            background-image: url('../../public/images/vedic-bg-img-2.png');
            background-size: cover;
            background-repeat: repeat;
        }

        50% {
            background-image: url('../../public/images/vedic-bg-img-3.png');
            background-size: cover;
            background-repeat: repeat;
        }
    }


    .header-container-vedic-main-top {
        display: flex;
        justify-content: center;
        position: relative;
        background: none;
        width: 100%;
    }

    .header-heading-elevate-txt {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 900;
        width: 100%;
        text-align: center;
    }

    .header-background-image {
        width: 0px;
    }

    .header-container-vedic {
        background-color: rgba(0, 0, 0, 0.4);
        padding: 30px;
        position: absolute;
        top: 45px;
    }

    .header-heading {
        color: #232321;
        font-size: 18px;
        font-weight: 700;
        width: -moz-fit-content;
        width: fit-content;
    }

    .header-para {
        margin: 25px;
        color: #FFFFFF;
        font-size: 12px;
    }

    .getProposal-btn {
        background-color: #CE3082;
        padding: 10px 15px 10px 15px;
        color: #FFFFFF;
        border: none;
        border-radius: 5px;
        font-size: 10px;
    }

    .checkWork-btn {
        border: 1px solid #FAEAF3;
        background-color: #00000066;
        color: #FFFFFF;
        padding: 10px 15px 8px 15px;
        font-size: 10px;
        border-radius: 5px;
    }

    .arrow-btn {
        margin-left: 5px;
    }

    .header-btn-div {
        display: flex;
        gap: 10px;
        justify-content: center;
    }

    .header-container {
        margin: 0px;
    }

    .header-about-main {
        display: flex;
    }

    .header-about-left {
        text-align: start;
        padding: 0px !important;
    }

    .header-about-left-heading {
        width: 100%;
        font-weight: 600;
        font-size: 25px;
    }

    .header-about-left-top-text {
        color: #CE3082;
        margin: 10px 0px 10px 0px;
    }


    .header-about-left-para {
        color: #767676;
        width: 100%;
        font-size: 15px;
    }


    .keyElements-heading {
        color: #232321;
        text-align: start;
        font-size: 20px;
        margin-bottom: 30px;
    }

    .keyElements-bottom-main {
        text-align: start;
    }

    .keyElements-bottom-main-para {
        color: #595959;
        width: 100%;
        padding: 0px !important;
        font-size: 14px;
    }

    .black-image {
        height: 218px;
        width: 350px;
    }

    .header-keyElements-container {
        margin-top: 30px;
    }

    .keyElements-bottom-container {
        margin: 0px;
    }

    .keyElements-bottom-main-heading-text {
        margin: 15px 0px 10px 0px;
    }

    .header-ourprocess-div {
        margin: 0px;
        margin-bottom: 50px;
    }

    .streamlined-heading {
        font-size: 20px !important;
        font-weight: 600;
    }


}

@media(min-width:768px) and (max-width:990px) {
    .header-bg-images {
        min-width: 100%;
        height: 60vh;
        animation: animate 10s ease-in-out infinite;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .header-container-vedic {
        background-color: rgba(0, 0, 0, 0.4);
        width: 80%;
        padding: 30px;
        position: absolute;
        top: 150px;
    }

    .header-about-left-heading {
        width: 90%;
        font-weight: 700;
        font-size: 20px;
    }

    .header-heading {
        display: flex;
        font-size: 15px;
    }

    .header-about-left-para {
        color: #767676;
        width: 100%;
        font-size: 12px;
    }

    .header-about-main {
        display: flex;
        margin-top: 10px;
    }

    .keyElements-bottom-container {
        margin-top: 25px;
    }

    .header-keyElements-container {
        margin-top: 20px;
    }

    .streamlined-heading {
        font-family: Noto Sans;
        font-size: 25px;
        font-weight: 600;
        color: #232321;
    }

    .keyElements-heading {
        color: #232321;
        text-align: center;
        font-weight: 700;
        font-size: 25px;
    }

    .header-ourprocess-heading {
        font-size: 30px;
        font-weight: 600;
    }

    .streamlined-heading {
        font-size: 24px;
    }

    .header-ourprocess-div {
        margin: 20px 30px 40px 5px;
    }
}

@media(min-width:991px) and (max-width:1120px) {
    .header-about-left-heading {
        width: 90%;
        font-weight: 700;
        font-size: 28px;
    }

    .black-image {
        height: 410px;
        width: 102%;
    }

    .header-container-vedic-main-top {
        position: relative;
        height: 35rem !important;
    }

}