.collection-main {
    background-color: #FAEAF3;
    padding: 70px;
    margin: 40px 0px 60px 35px;
}
.collection-heading {
    font-family: Noto Sans;
    font-size: 35px;
    font-weight: 700;
    color: #232321;
    display: flex;
    justify-content: center;
}
.collection-num-para-div {
    display: flex;
    justify-content: center;
    gap: 132px;
    line-height: 15px;
    height: 8rem;
    align-items: end;
}
.collection-number {
    font-family: Noto Sans;
    font-size: 40px;
    font-weight: 700;
    color: #CE3082;
    text-align: center;
}
.collection-para {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 500;
    color: #232321;
}


@media (max-width:767px ){
    .collection-num-para-div {
        display: block !important;
    }
    .collection-main {
        background-color: #FAEAF3;
        padding: 15px !important;
        margin: 0px;
        height: 23rem;
        width: 21.5rem;
    }
    .collection-heading {
        font-family: Noto Sans;
        font-size: 22px !important;
        font-weight: 600;
        color: #232321;
        display: flex;
        justify-content: center;
        text-align: center;
        padding-top: 10px;
    }
    
    .collection-number {
        font-family: Noto Sans;
        font-size: 28px;
        font-weight: 700;
        color: #CE3082;
        text-align: center;
    }
    .collection-para {
        font-family: Noto Sans;
        font-size: 20px;
        font-weight: 500;
        color: #232321;
        text-align: center;
    }
   
   
}
@media (min-width:768px)and (max-width:990px){
    .collection-para {
        font-family: Noto Sans;
        font-size: 18.5px;
        font-weight: 500;
        color: #232321;
        line-height: 28px;
        width: 100%;
    }
    .collection-num-para-div {
        display: flex;
        justify-content: center;
        gap: 60px !important;

    }
    .collection-main {
        background-color: #FAEAF3;
        padding: 55px;
        margin: 25px 20px 45px 20px;
    }
    .collection-heading {
        font-family: Noto Sans;
        font-size: 26px;
        font-weight: 700;
        color: #232321;
       width: 100%;
    }
    .a-collecttion-heading {
        font-family: Noto Sans;
        font-size: 40px;
        font-weight: 600;
        color: #232321;
    }
    .projects-heading {
        font-family: Raleway;
        font-size: 30px;
        font-weight: 500;
        color: #CE3082;
    }
}

@media(min-width:991px) and (max-width:1120px) {
.collection-heading {
    font-size: 34px;
    font-weight: 600;
   
}
}