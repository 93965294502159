.test-main {
    background-color: rgba(255, 255, 255, 1);
}

.testimonial-top-container {
    text-align: center;
    line-height: 15px;
}

.testimonial-main-heading {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    color: #CE3082;
}

.testimonial-heading {
    font-family: Noto Sans;
    font-size: 52px;
    font-weight: 550;
    color: #232321;
}

.testimonial-para {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 400;
    color: #767676;
}

.testimonial-inverted-comma-img {
    height: 55px;
}

.testimonial-body-para {
    font-family: Noto Sans;
    font-size: 30px;
    font-weight: 600;
    width: 29rem;
    line-height: 35px;
    text-align: left;
    color: #767676;
}

.testimonial-discover {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    color: #7E1D4F;
    text-align: start;
    padding: 8px 8px 0px 8px;
}

.testimonial-contemporary {
    font-family: Raleway;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 2px;
    color: #767676;
    text-align: start;
    margin: 10px 0px 20px 0px;
    padding: 0px 5px 0px 5px;
}

.testimonial-body-container {
    height: 28rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
}

.test {
    overflow-x: scroll;
    display: flex;
}

.testimonial-body-right-container {
    display: flex;
    gap: 5px;
    margin-left: 155px;
}

.test::-webkit-scrollbar {
    width: 0;
}

/* Hide scrollbar for Firefox */
.test {
    scrollbar-width: none;
    overflow-x: none;
    display: flex;
}

.testimonial-img {
    height: 220px;
    width: 330px
}

.testimonial-body-left-container {
    text-align: left;
    /* padding: 0px 0px 100px 122px;
    width: 60rem; */
}

.testimonial-card {
    width: 17.5rem;

}

.testimonial-main-container {
    margin: 20px 0px 20px 5px;
}

@media (max-width:767px) {
    .testimonial-main-container {
        margin: 0px;
    }

    .testimonial-main-heading {
        font-family: Raleway;
        font-size: 28px;
        font-weight: 600;
        color: #CE3082;
    }

    .testimonial-body-left-container {
        padding: 0px 0px 20px 0px !important;
    }

    /* .row>* {
        padding-left: 0px !important;
        padding-right: 0px !important;
    } */

    .testimonial-heading {
        font-family: Noto Sans;
        font-size: 28px;
        font-weight: 550;
        color: #232321;
    }

    .testimonial-body-para {
        font-family: Noto Sans;
        font-size: 23px;
        font-weight: 600;
        width: 100%;
        line-height: 31px;
        text-align: left;
        color: #767676;
        padding: 0px 0px 0px 0px;
    }

    .testimonial-body-container {
        height: 35rem;
        align-items: center;
        display: block;
        margin-left: 0px;
        width: 100%;
    }

    .testimonial-body-right-container {
        height: 20%;
    }

    .testimonial-card {
        width: 17.5rem;
    }


    .testimonial-para {
        font-family: Noto Sans;
        font-size: 25px;
        line-height: 25px;
        font-weight: 400;
        color: #767676;
    }

    .testimonial-top-container {
        text-align: center;
        line-height: 15px;
        margin-top: 30px;
    }

    .testimonial-inverted-comma-img {
        display: none;

    }

    .testimonial-body-right-container {
        display: flex;
        gap: 10px;
        margin-left: 27px;
    }

    .testimonial-body-left-container {
        text-align: left;
        padding: 0px 0px 20px 20px;
        width: 100%;
    }

}

@media(min-width:768px) and (max-width:990px) {
    .testimonial-main-heading {
        font-family: Raleway;
        font-size: 38px;
        font-weight: 600;
        color: #CE3082;
    }

    .testimonial-para {
        font-family: Noto Sans;
        font-size: 22px;
        font-weight: 400;
        color: #767676;
    }

    .testimonial-heading {
        font-family: Noto Sans;
        font-size: 48px;
        font-weight: 550;
        color: #232321;
        margin: 20px 0px 25px 0px;
    }

    .testimonial-body-left-container {
        text-align: left;
        padding: 30px 0px 0px 0px;
        width: 100%;
    }

    .testimonial-body-container {
        height: 40rem;
        align-items: center;
        display: block;
        margin-left: 0px;
        width: 100%;
    }

    .testimonial-body-right-container {
        display: flex;
        gap: 5px;
        margin-left: 0px;
        margin-top: 20px;
    }

    .testimonial-body-para {
        font-family: Noto Sans;
        font-size: 37px;
        font-weight: 600;
        width: 100%;


        line-height: 35px;
        text-align: left;
        color: #767676;
        margin-bottom: 20px;
    }

    .testimonial-body-para {
        font-size: 30px;
    }

    .testimonial-inverted-comma-img {
        display: none;
    }

    .testimonial-body-container {
        height: 33rem;
        align-items: center;
        display: block;
        margin-left: 0px;
        width: 100%;
    }
}


@media(min-width:991px) and (max-width:1120px) {
    .testimonial-inverted-comma-img {
        display: none;
    }

    .testimonial-main-heading {
        font-size: 30px;
        font-weight: 600;
    }

    .testimonial-heading {
        font-size: 48px;
        font-weight: 600;
    }

    .testimonial-body-left-container {
        padding: 0px 0px 100px 30px;
    }

    .testimonial-body-right-container {
        display: flex;
        gap: 5px;
        margin-left: 10px !important;
    }
}