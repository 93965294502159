.projects-heading {
    font-family: Raleway;
    font-size: 32px;
    font-weight: 600;
    color: #CE3082;
}
.projects-main-div{

}
.a-collecttion-heading-project {
    font-family: Noto Sans;
    font-size: 45px;
    font-weight: 600;
    color: #232321;
}

.discover-para {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 400;
    color: #767676;
}

.projects-top-div {
    line-height: 20px;
    padding-left: 40px;
    text-align: start;
}

.project-img-content {
    position: relative;
    display: flex;
}

.project-content-div {
    position: absolute;
    left: 0px;
    bottom: 50px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
}

.project-contemporary-heading {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFFFFF;
}

.project-discover-para {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    color: #F9F9F9;
    width: 220px;
}

.project-img-collection {
    padding: 0px 0px 0px 40px;
}

.proj-img {
    width: 36rem;
    height: 36rem;

}


.project-img-content:hover .project-content-hover-div {
    display: block;
    line-height: 12px;
}

.project-content-div:hover {
    content-visibility: visible;
    padding: 20px 30px 10px 30px;
}

.project-content-hover-div {
    display: none;
    text-align: center;
}

.proj-location {
    color: #FFFFFF;
}

.proj-hyd {
    color: rgba(249, 249, 249, 1);
    padding-left: 20px;
}

.project-contemporary-discover-div {
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 50px 5px 50px;
}

.project-building-status-hover {
    padding-right: 50px;
}

.project-img-content:hover .project-content-hover-div {
    display: block;
}

.project-img-content:hover .project-content-div {
    padding: 20px 30px 10px 30px;
}

.project-content-hover-div {
    display: none;
    text-align: center;
}

.project-img-content:hover .project-img {
    filter: blur(5px);
}

.project-img-collection {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.project-img-collection>div {
    flex: 1;
    transition: flex 0.5s;
    overflow: hidden;
}

.project-img-collection>div:hover {
    flex: 2;
}

.project-img-content:hover .project-content-div {
    transform: translateY(0);
}

.projectimg1-div:hover .proj-img {
    /* border: 2px solid red; */
    width: 62rem;
    height: 36rem;
}

/* .projectimg2-div:hover .proj-img-02 {
    width: 60rem;
    height: 38rem;
} */

@media (max-width:767px) {
    .project-img-collection {
        padding: 0px !important;
    }
    .projects-top-div {
        line-height: 20px;
        text-align: start;
        padding: 0px;
        margin: 0px;
    }

    .project-img-collection {
        display: block;
    }

    .project-content-div {
        position: absolute;
        left: 0px;
        bottom: 50px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
    .projects-main-div {
        margin-bottom: 30px;
    }
       
    .project-contemporary-discover-div {
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px;
    }

    .proj-img {
        width: 24rem;
        height: 19rem;
    }
    .project-discover-para {
        font-family: Noto Sans;
        font-size: 12px;
        font-weight: 400;
        color: #F9F9F9;
        width: 139px;
    }

    .project-contemporary-heading {
        font-family: Noto Sans;
        font-size: 12px;
        font-weight: 0;
        color: #FFFFFF;
    }

    .projects-heading {
        font-family: Raleway;
        font-size: 30px !important;
        font-weight: 600;
        color: #CE3082;
    }

    .a-collecttion-heading-project {
        font-family: Noto Sans;
        font-size: 18px !important;
        font-weight: 600;
        color: #232321;
    }

    .projects-heading {
        font-family: Raleway;
        font-size: 25px;
        font-weight: 500;
        color: #CE3082;
    }
}

@media (min-width:768px) and (max-width:990px) {
    .proj-img {
        width: 20rem;
        height: 28rem;
    }

    .proj-img-div {
        width: 20rem;
        height: 28rem;
    }
.projects-main-div {
    margin-bottom: 30px;
}
    .projectimg1-div:hover .proj-img,
    .projectimg3-div:hover .proj-img {
        width: 57rem;
        height: 28rem;
    }

    .a-collecttion-heading-project {
        font-family: Noto Sans;
        font-size: 30px;
        font-weight: 600;
        color: #232321;
    }
    .projects-heading {
        font-family: Raleway;
        font-size: 38px;
        font-weight: 600;
        color: #CE3082;
    }
    .project-img-collection {
        padding: 0px 0px 0px 0px !important;
    }
    .projects-top-div {
        padding-left: 0px !important;
    }
    .project-contemporary-discover-div {
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 15px 5px 15px;
    }

    .project-content-div {
        padding: 10px 0px 0px 0px !important;
    }
}

@media(min-width:991px) and (max-width:1120px) {
    
.a-collecttion-heading-project {
    font-size: 38px !important;
    font-weight: 600 !important;
}
.projects-heading {
    font-size: 31px !important;
    font-weight: 600 !important;
    
}
}