.ourprocess-scroll-part-main {
    overflow-y: auto;
    height: 80vh;
    margin-right: 92px;
    margin-left: 98px;
    margin-bottom: 20px;
}

.main_Container {
    /* padding-top: 600px; */
}

/* Hide scrollbars for WebKit-based browsers (Chrome, Safari) */
.ourprocess-scroll-part-main::-webkit-scrollbar {
    width: 0;
}

.client-core-execute-div {
    text-align: start;
}

.ourprocess-number.sticky {
    position: fixed;
}

.number-client-core-execute-main-div-one {

    display: flex;
    justify-content: center;
    gap: 30px;
    height: auto;
    background-color: transparent;
    margin-top: 10px;
    margin-bottom: 40px;
}

.number-client-core-execute-main-div-two {

    display: flex;
    justify-content: center;
    margin-top: 80px;
    gap: 35px;
    height: auto;
    background-color: transparent;
    margin-bottom: 40px;
}

.number-client-core-execute-main-div-three {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    gap: 35px;
    height: auto;
    background-color: transparent;
}

.ourprocess-number-one {
    margin: 30px;
    height: 70px;
    width: 120px;
}

.ourprocess-number-two {
    margin: 30px;
    height: 70px;
    width: 120px;
}

.ourprocess-number-three {
    margin: 30px;
    height: 70px;
    width: 120px;
}

.client-core-execute-heading {
    font-family: Noto Sans;
    font-size: 30px;
    font-weight: 700;
}

.client-core-execute-para {
    font-family: Noto Sans;
    font-size: 18px;
    font-weight: 400;
    color: #767676;
    width: 70%;
    margin-top: 10px;
}

.ourprocess-images {
    height: 426px;
    width: 100%;
}

.ourprocess-number-fixed-image {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 70px;
    width: 130px;
}

.ourprocess-main {
    position: relative;
}

@media (max-width:768px) {

    .residentila_container,
    .commercial_content {
        padding: 0px 15px 0px 15px !important;
    }

    .ourprocess-top-div {
        padding: 0px !important;
    }

    .collection-main {
        width: 100%;
        margin: 0px;
    }

    .ourprocess-scroll-part-main {
        padding: 0px;
        height: 55vh;
        margin-right: 5px;
        margin-left: 15px;

    }


    .number-client-core-execute-main-div-one {
        display: flex;
        gap: 30x;
        height: 40vh;
    }

    .number-client-core-execute-main-div-two {

        display: flex;
        justify-content: center;
        margin-top: 40px;
        gap: 30px;
        height: 360vh;
        background-color: transparent;
    }

    .number-client-core-execute-main-div-three {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        gap: 30px;
        height: 50vh;
        background-color: transparent;
    }

    .ourprocess-images {
        height: 115px;
    }

    .client-core-execute-para {
        width: 100%;
    }

    .client-core-execute-heading {
        font-family: Noto Sans;
        font-size: 26px;
        font-weight: 700;
    }

    .ourprocess-number-fixed-image {
        /* border: 2px solid red; */
        position: sticky;
        top: 0;
        z-index: 1;
        height: 53px;
        width: 60px;
    }

}

@media (min-width: 429.5px) and (max-width: 430.5px) {
    .ourprocess-scroll-part-main {
        padding: 0px;
        height: 55vh;
        margin-right: 5px;
        margin-left: 15px;

    }

    .number-client-core-execute-main-div-two {

        display: flex;
        justify-content: center;
        margin-top: 40px;
        gap: 30px;
        height: 320vh;
        background-color: transparent;
    }
    .number-client-core-execute-main-div-three {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        gap: 30px;
        height: 40vh;
        background-color: transparent;
    }

}

@media(min-width:768px) and (max-width:990px) {
    .ourprocess-scroll-part-main {
        padding: 0px;
        height: calc(52vh - 150px);
        margin-bottom: 20px;
        margin-left: 0px;
    }

    .ourprocess-number-fixed-image {
        /* border: 2px solid red; */
        position: sticky;
        top: 0;
        z-index: 1;
        height: 53px;
        width: 70px;
        margin-left: 30px;
    }

    .number-client-core-execute-main-div-one {

        display: flex;
        gap: 30px;
        height: calc(52vh - 150px);
    }

    .number-client-core-execute-main-div-two {

        display: flex;

        margin-top: 30px;
        gap: 35px;
        height: calc(200vh - 60px);

    }

    .client-core-execute-para {
        width: 100%;
    }

    .ourprocess-images {
        height: 226px;
    }
}

@media(min-width:991px) and (max-width:1120px) {
    .client-core-execute-div {
        height: calc(52vh - 150px);
    }

    .number-client-core-execute-main-div-one {
        display: flex;
        justify-content: center;
        gap: 30px;
        height: calc(45vh - 0px);
        background-color: transparent;
        margin-left: 0px;
    }

    .ourprocess-images {
        height: 265px;
        width: 100%;
    }

    .header-ourprocess-heading {
        font-size: 34px !important;
        font-weight: 600 !important;
    }

    .streamlined-heading {
        font-family: Noto Sans;
        font-size: 32px !important;
        font-weight: 600;
        color: #232321;
    }

    .ourprocess-scroll-part-main {
        padding: 0px;
        height: calc(40vh - 100px);
        margin-bottom: 0px;
    }

    .ourprocess-number-fixed-image {
        height: 70px;
        width: 92px;
    }

}