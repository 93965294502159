.aboutus-main {
  /* border: 2px solid black; */
  margin-top: 30px;
}

.input-ele {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid lightgray;
}

.error {
  border: 1px solid rgb(195, 42, 42);
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

.aboutus-top-main-heading {
  color: #ce3082;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.aboutus-top-inner {
  display: flex  !important;
  justify-content: space-around;
  position: relative;
  /* border: 2px solid black; */
  margin-top: 40px !important;
}
.col-6 {
  width: 50%;
}
.aboutus-top-left-heading {
  color: #232321;
  width: 100%;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 30px;
}

/* .aboutus-top-right {
    position: absolute;
} */
.aboutus-top-right-image {
  height: 400px;
  width: 100%;
  margin-left: 225px;
}

.aboutus-top-left {
  backdrop-filter: blur(8px);
  background: #ffffff40;
  height: 85%;
  left: -30px;
  margin: 25px 0 30px 80px;
  padding: 20px 12px 10px 30px;
  position: absolute;
  text-align: start;
  top: 23px;
  width: 50% !important;
}

.aboutus-top-left-para1,
.aboutus-top-left-para2 {
  color: #626262;
  font-size: 14px;
  /* border: 2px solid black; */
  width: 100%;
}

.aboutus-top-right {
  width: 80%;
}

.aboutus-keyElements-container {
  margin: 80px 0px 90px 0px;
}

.aboutus-keyElements-heading {
  margin-bottom: 60px;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}

.aboutus-keyElements-bottom-main {
  text-align: start;
  /* border: 2px solid black; */
  padding: 0px 0px 0px 10px;
  margin-left: 40px;
}

.aboutus-keyElements-bottom-main-heading-text {
  margin: 15px 0px 15px 0px;
}

.aboutus-keyElements-images {
  height: 60px;
}

.aboutus-keyElements-bottom-main-para {
  color: #595959;
}

.aboutus-founder-main {
  margin: 0px 10px 30px 35px;
}

.aboutus-founder-inner-left {
  padding-left: 20px;
}

.black-img {
  /* border: 2px solid black; */
  padding: 0px 90px 0px 15px;
  height: 330px;
  width: 580px;
}

.aboutus-founder-inner-right {
  text-align: start;
  padding: 30px 0px 30px 0px;
}

.aboutus-founder-inner-right-para1,
.aboutus-founder-inner-right-para2 {
  color: #626262;
}

.aboutus-founder-inner-right-heading2 {
  color: #666666;
}

.aboutus-founder-inner-right-heading1 {
  font-weight: 700;
}

.contactus-main {
  /* border: 2px solid black; */
  margin: 60px 0px 80px 45px;
}

.contactus-main-inner-top {
  display: flex;
  justify-content: center;
}

.contactus-main-inner-top-heading {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 30px;
  text-align: center;
}

.contactus-main-inner-top-para {
  width: 68%;
  color: #626262;
  font-size: 16px;
  text-align: center;
}

.contactus-main-inner-bottom {
  margin-top: 30px;
}

.contactus-main-inner-bottom-form {
  text-align: start;
}

.contactus-desks-div {
  text-align: start;
}

.form-label {
  color: #0c0c0c;
}

#desk {
  padding: 5px 5px 60px 15px;
  resize: none;
  scrollbar-width: none;
  width: 100%;
  border-radius: 5px;
}

.contactus-submit-btn-div {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.contactus-submit-btn {
  color: #f8fafc;
  background-color: #ce3082;
  border: none;
  border-radius: 5px;
  padding: 10px 30px 10px 30px;
}

.contactus-star {
  color: red;
}

/* What we do - Start */
.whatWedo_mainContainer {
  /* height: 280px; */
  width: 100%;
  margin: auto;
  color: #767676;
  font-size: 12px;
}

.aboutus-top-inner {
  justify-content: space-around;
  margin-top: 40px;
  position: relative;

}

.whatWedo_imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatWedo_imageContainer img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.whatWedo_textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
}

.fw-bold {
  font-weight: bold;
  color: #232321;
}
/* .whoWeAre_mainContainer {
  margin-top: 80px !important;
} */
.whoWeAre_mainContainer {
    margin-top: 40px;
  }
  
  .who-we-are-title {
    color: #232321;
  }
  
  .who-we-are-text {
    line-height: 1.5;
    padding: 0 15px;
  }
  
  .who-we-are-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }
  
  .who-we-are-image {
    height: 300px !important;
    width: 100%;
  }


  .awards-title {
    color: #000000;
  }
  
  .awards-description {
    color: #767676;
    font-size: 13px;
  }
  
  .award-image {
    height: 250px;
    width: 100%;
    object-fit: cover;
  }

@media (max-width: 576px) {
  .whatWedo_imageContainer img {
    height: 150px;
    width: 100%;
  }

  .contactus-main-inner-top-para {
    width: 100% !important;
  }
  /* .contactus-main-inner-bottom-form {
    padding: 0px !important;
  } */

  .col-6 {
    margin: 0px !important;
    width: 100% !important;
  }

  .aboutus-keyElements-bottom-main {
    margin-left: 0px !important;
    padding: 0px !important;
  }

  .whatWedo_textContainer {
    font-size: 12px;
  }

  .whatWedo_mainContainer {
    padding: 10px;
  }
}



/* Responsive adjustments */
@media (max-width: 767px) {
  .who-we-are-text {
    padding: 15px;
  }
  .awards-description {
    font-size: 12px;
  }

  .award-image {
    height: 200px;
    margin-top: 10px;
  }

  .who-we-are-image {
    height: 180px;
  }
  .whatWedo_mainContainer {
    height: auto;
    font-size: 14px;
  }
  .fw-bold{
    margin-top: 10px;
  }

  .whatWedo_imageContainer img {
    height: 200px;
  }

  .whatWedo_textContainer {
    margin-top: 10px;
    /* text-align: center; */
  }
  .aboutus-top-right {
    width: 100%;
  }

  .aboutus-top-right-image {
    height: 250px;
    width: 100%;
    margin-left: 0px;
  }

  .aboutus-main {
    margin: 0px;
  }

  .aboutus-top-left {
    position: static;
    /* top: 5px; */
    /* left: -30px; */
    width: 100% !important;
    margin: 0px;
    padding: 0px;
    height: auto;
  }

  .black-img {
    height: 100%;
    width: 100%;
    padding: 0px;
  }

  .contactus-main-inner-bottom-form {
    margin: 0px;
    padding: 0px 20px 0px 20px;
  }

  .contactus-main {
    margin: 60px 0px 80px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .who-we-are-image {
    height: 190px;
  }
  .aboutus-top-right-image {
    height: 400px;
    width: 71%;
    margin-left: 216px;
  }
  .aboutus-top-left {
    margin: 0px;
  }
  .awards-description {
    font-size: 13px;
    margin-top: 10px;
  }
.aboutus-top-left{
  backdrop-filter: blur(8px);
  background: #ffffff40;
  height: 85%;
  left: 0px;
  margin: 0px;
  padding: 20px 12px 10px 30px;
  position: absolute;
  text-align: start;
  top: 23px;
  width: 50% !important;
}
  .award-image {
    height: 220px;
    margin-top: 10px;
  }
  .aboutus-keyElements-bottom-main{
    text-align: start;
  padding: 0px;
  margin: 0px;
  }
  .who-we-are-image {
    height: 200px;
  }
  .awards-description {
    font-size: 14px;
    margin-top: 10px;
  }

  .award-image {
    height: 250px;
  }
}



