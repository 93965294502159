.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.preview-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.preview-image {
  max-width: 145px;
  max-height: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

/* .form-group {
    margin-bottom: 15px;
  } */

.form-group label {
  display: block;
  font-family: Noto Sans;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  font-family: Noto Sans;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
}

.form-buttons {
  display: flex;
  font-family: Noto Sans;
  justify-content: space-between;
  margin-top: 20px;
}

.form-buttons button {
  padding: 10px 15px;
  border: none;
  font-family: Noto Sans;
  border-radius: 4px;
  cursor: pointer;
}
.preview-image-container {
  position: relative;
}
.delete-button {
  position: absolute;
  right: 0;
  color: red;
  /* font-family: Noto Sans;
    border-radius: 50%; */
  border: none;
  cursor: pointer;
  /* background-color: red; */
}
.form-buttons button[type="submit"] {
  background-color: #d65397;
  color: #fff;
}

.swiper {
  height: auto;
}
.form-buttons button[type="button"] {
  background-color: #6c757d;
  color: #fff;
}
.portfolio-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.portfolio-item {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px; /* Adjust width as needed */
  padding: 15px;
  font-family: Noto Sans;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
  position: relative;
}

.portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.portfolio-item h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.portfolio-item p {
  font-size: 1rem;
  color: #666;
  margin: 5px 0;
}

.portfolio-images {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.portfolio-image {
  width: 100%;
  height: 168px;
  border-radius: 4px;
  object-fit: cover;
}
.modal-header {
  padding: 0px !important;
}
.modal-body {
  padding: 0px !important;
}
.portfolio-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.portfolio-buttons button {
  background-color: #d65397;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

/* .portfolio-buttons button:hover {
  background-color: #0056b3;
} */

.portfolio-buttons button:nth-of-type(2) {
  background-color: #dc3545;
}

/* .portfolio-buttons button:nth-of-type(2):hover {
  background-color: #c82333;
} */
