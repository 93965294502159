.contact-btn {
  border: 1px solid #0c0c0c;
  background-color: #0c0c0c !important;
  color: #ffffff !important;
}

.contact-btn:hover {
  border: 1px solid #0c0c0c;
  background-color: #0c0c0c;
  color: #ffffff;
}

.navbar-main-container {
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.navbar-right-list {
  display: flex;
  gap: 20px;
}
