.portifolio-main {
  padding-right: 0px;
  background-color: white;
}

.portfolio-main-container {
  background-color: white;
}


.portifolio-imgs-div {
  display: flex;
  flex-direction: column;
  height: 12rem;
  width: 0rem;
  gap: 15px;
  padding-top: 30px;
  padding-right: 2rem;
}

.portifolio-all-imgs {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.portifolio-big-img {
  width: 780px;
  height: 440px;
  padding-left: 30px;
}

.portifolio-right-img {
  width: 13rem;
  height: 7rem;
}

.portifolio-heading {

  padding: 20px;
  font-family: Raleway;
  font-size: 40px;
  font-weight: 700;
  color: rgba(206, 48, 130, 1);
}

.portifolio-blur-container {
  border-left: 1px solid rgba(0, 0, 0, 1);
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  position: absolute;
  right: 44.5rem;
  bottom: 80px;
  padding: 20px 40px 20px 20px;
}

.portifolio-blur-img-container-div {
  position: relative;
  padding-left: 55px;
}

.portifolio-blur-inside-container {
  line-height: 15px;
}

.portifolio-comtemporary {
  font-family: Raleway;
  font-size: 19px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  width: 13rem;
  text-align: start;
}

.portifolio-discover {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  color: rgba(98, 98, 98, 1);
  width: 15rem;
  text-align: left;
}

.portifolio-location-build-proj {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 500;
  text-align: start;
  display: flex;
  gap: 10px;
}

.portifolio-location-build-proj-right {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  color: rgba(98, 98, 98, 1);
  text-align: start;
}

.portifolio-comtemporary-discover-div {
  line-height: 19px;
}

.selected {
  border: 5px solid rgba(144, 34, 91, 1);
}

.swiper-project-name {
  width: 100%;
  font-family: Raleway;
  font-size: 56px;
  font-weight: 600;
  line-height: 94px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 0.15);
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  width: calc(var(--swiper-navigation-size) / 44* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  top: 670px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none !important;
}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

.pagenation-btn {
  display: flex;
  height: 170px;
}

.swiper-nav-btns {
  display: flex !important;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 60px 0px 150px 0px;

}

.right-btn {
  display: flex;
  width: 100%;
  padding-left: 90px;
}

.left-btn {
  width: 100%;
  display: flex;
  padding-right: 150px;
  justify-content: flex-end;
}

.left-nav-swiper-button {
  width: 100%;
  text-align: start;
  border-style: none !important;
  background-color: white;
  font-weight: bold;
}

.right-nav-swiper-button {
  width: 100%;
  border-style: none !important;
  background-color: white;
  font-weight: bold;
  text-align: end;
}

@media (max-width: 767px) {

  .portifolio-right-img {
    width: 6.8rem;
    height: 6.5rem;
  }

  .portifolio-big-img {
    width: 100%;
    height: 20rem;
    padding-left: 0px;
    margin-top: 160px;
  }

  .portifolio-imgs-div {
    margin-top: 30px;
    flex-direction: row;
    height: 9rem;
    width: 0rem;
    padding: 0px 0px;
    padding-right: 0px;

  }

  .portifolio-heading {
    padding: 15px;
    font-family: Raleway;
    font-size: 21px;
  }
.portifolio-swiper-nav-btn{
  margin-bottom: 25px;
}
  .portifolio-blur-container {
    border-left: none;
    background: rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: absolute;
    bottom: 504px;
    left: 20px;
    width: 100%;
    height: 9rem;
    padding: 5px;
  }

  .portifolio-comtemporary {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    width: 13rem;
    text-align: start;
  }

  .portifolio-blur-inside-container {
    line-height: 7px;
  }

  .portifolio-blur-img-container-div {
    position: static;
    padding-left: 0px !important;
  }

  .portifolio-location-build-proj {
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    text-align: start;
    display: flex;
    gap: 1px;
    margin-bottom: 13px;
  }

  .swiper-project-name {
    width: 100%;
    font-family: Raleway;
    font-size: 19px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 0, 0, 0.15);
    padding: 15px 0px;
    margin: 0px !important;
  }

  .left-btn {
    width: 100%;
    display: flex;
    padding-right: 15px;
    justify-content: flex-end;
  }

  .right-btn {
    display: flex;
    width: 103%;
    padding-left: 25px;
  }

  .swiper-nav-btns {
    display: flex !important;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 0px 0px 0px 0px;
  }

  .left-nav-swiper-button {
    width: 108.33px !important;
    text-align: center;
    border-style: none !important;
    background-color: white;
    font-weight: bold;
    font-size: 12px;
  }

  .right-nav-swiper-button {
    width: 100%;
    border-style: none !important;
    background-color: white;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }

  .portifolio-all-imgs {
    display: block;
    flex-direction: row;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .portifolio-big-img {
    width: 400px;
    height: 290px;
    padding-left: 0px;
  }

  .portifolio-heading {
    padding: 15px;
    font-family: Raleway;
    font-size: 30px;
    font-weight: 700;
    color: rgba(206, 48, 130, 1);
  }

  .portifolio-right-img {
    width: 8.5rem;
    height: 7rem;
  }
  .portifolio-swiper-nav-btn{
    margin: 60px 0px 30px 0px !important;
  }
  .portifolio-blur-container {
    border-left: 1px solid rgba(0, 0, 0, 1);
    background: rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: absolute;
    right: 225px;
    bottom: 25px;
    padding: 15px 20px 15px 15px;
  }
  .portifolio-location-build-proj-right {
    font-size: 14px;
}

  .portifolio-imgs-div {
    display: flex;
    flex-direction: column;
    height: 12rem;
    width: 0rem;
    gap: 15px;
    padding-top: 0px;
    padding-right: 2rem;
  }

  .portifolio-all-imgs {
    display: flex;
    justify-content: center;
  }

  .portifolio-blur-img-container-div {
    position: relative;
    padding-left: 0px;
  }

  .swiper-nav-btns {
    display: flex !important;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 0px 0px 0px 0px;
  }

  .swiper-project-name {
    width: 100%;
    font-family: Raleway;
    font-size: 46px;
    font-weight: 600;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 0, 0, 0.15);
  }

  .left-btn {
    width: 100%;
    display: flex;
    padding-right: 50px;
    justify-content: flex-end;
  }

  .right-btn {
    display: flex;
    width: 100%;
    padding-left: 50px;
  }

  .portifolio-comtemporary {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    width: 10rem;
    text-align: start;
  }

  .portifolio-blur-inside-container {
    line-height: 10px;
  }
}

@media(min-width:991px) and (max-width:1120px) {
  .portifolio-big-img {
    width: 580px;
    height: 345px;
    padding-left: 0px;
}
.portifolio-blur-container {
  right: 460px;
  bottom: 30px;
  padding: 20px 40px 20px 20px;
}
.portifolio-right-img {
  width: 10rem;
  height: 6rem;
}
.portifolio-all-imgs {
  gap: 25px;
}
}