.Footer-main {
  background-color: #1e1e1e;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.footer-left-heading {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.Footer-left {
  text-align: left;
  margin-right: 200px;
}

.quick-link-li-item {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #d65397;
  list-style: none;
}

.footer-li-items {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #e4e4e4;
  list-style: none;
  text-decoration: none;
}

.footer-ul-items {
  line-height: 30px;
  text-align: left;
  font-size: 60px;
}

.footer-vedic-logo {
  height: 10rem;
  width: 9rem;
  padding-bottom: 40px;
}

.Footer-right {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.bottom-div {
  background-color: #1e1e1e;
}

.bottom-line {
  display: flex;
  border-bottom: 1px solid #e2e8f0;
  margin: 0px 0px 0px 0px;
}

.footer-copyright-para {
  color: #ffffff;
  width: 46rem;
  margin-right: 50px;
  padding-left: 12px;
  text-align: start;
}

.footer-icons-container {
  color: #ffffff;
  padding: 0px 0px 0px 10px;
  height: 2rem;
  width: 10rem;
  display: flex;
  justify-content: space-around;
}

.footer-icons {
  color: #ffffff;
}

.footer-bottom-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 40px 0px;
  margin: 0px;
}

.footer-quick {
  width: 100px;
}
.footer-ul-items {
  width: 9rem;
}
.address {
  width: 13rem;
}
.footer-right-items {
  margin-right: 5rem;
}

@media (max-width: 767px) {
  .Footer-main {
    background-color: #1e1e1e;
    display: block;

    padding-top: 10px;
  }

  .Footer-left {
    text-align: left;
    margin-right: 0px;
  }
  .footer-copyright-para {
    margin-right: 0px;
  }

  .footer-vedic-logo {
    height: 10rem;
    width: 9rem;
    padding-bottom: 0px;
    margin-left: 80px;
  }

  /* .footer-left-heading {
        font-family: Maven Pro;
        font-size: 23px;
        font-weight: 800;
        color: #FFFFFF;
    } */

  .Footer-right {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    gap: 0px;
  }

  .footer-vedic-logo {
    height: 10rem;
    width: 10rem;
    padding-top: 0px;
  }

  .Footer-right-left-ul {
    display: flex;
  }

  .footer-bottom-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 0px 0px !important;
    margin: 0px;
  }

  .bottom-line {
    display: flex;
    border-bottom: 1px solid #e2e8f0;
    margin: 0px;
  }

  .footer-icons-container {
    color: #ffffff;
    height: 2rem;
    width: 10rem;
    display: flex;
    gap: 20px;
    justify-content: space-around;
  }
  /* .address{
width: 100%;
  } */
}

@media (min-width: 768px) and (max-width: 990px) {
  /* .footer-left-heading {
    font-weight: 600 !important;
  } */

  .Footer-left {
    text-align: left;
    margin-right: 0px !important;
  }

  .footer-icons-container {
    width: 25rem;
  }
}
@media (min-width: 991px) and (max-width: 1120px) {
  /* .footer-left-heading {
        font-size: 24px;
    } */
}
