/* .userName-field{
    width: 340px;
    margin-left: 170px;
} */
.files-btn{
margin-left: 173px;
}

@media(max-width:556px){
    .userName-field{
        margin-left: 0px;
        width: 100%;
    }
    .files-btn{
        margin-left: 0px;
    }
}